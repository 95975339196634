<template>
  <main class="pb-14">
    <ValidationObserver
      ref="agendaForm"
      v-slot="{ invalid, handleSubmit }"
    >
      <form>
        <HeaderMenu>
          <section class="flex gap-4">
            <BaseButton
              type="button"
              :disabled="invalid"
              class="text-sm text-green-700 border-green-700 hover:bg-green-50 font-lato"
              data-cy="agenda-form__preview-button"
              @click="setModalStatus('PREVIEW')"
            >
              <template #icon-left>
                <JdsIcon
                  name="eye"
                  size="16px"
                  class="h-4"
                  :class="[invalid ? 'text-gray-600' : 'text-green-700']"
                />
              </template>
              <p>
                Pratinjau
              </p>
            </BaseButton>
            <BaseButton
              type="button"
              :disabled="invalid"
              class="text-sm text-white bg-green-700 hover:bg-green-600 font-lato"
              data-cy="agenda-form__submit-button"
              @click="handleSubmit(onSubmit)"
            >
              <SaveIcon
                class="w-5 h-5"
                :class="[invalid ? 'fill-gray-600' : 'fill-white']"
              />
              <p>
                {{ submitButtonLabel }}
              </p>
            </BaseButton>
          </section>
        </HeaderMenu>

        <section class="grid grid-cols-3 gap-4 agenda__form">
          <!-- LEFT COLUMN -->
          <div class="col-span-2">
            <!-- AGENDA IMAGE UPLOADER -->
            <fieldset class="flex flex-col p-4 mb-4 bg-white rounded-lg">
              <h2 class="mb-3 font-medium text-green-700 font-roboto">
                Upload Gambar
              </h2>
              <ValidationProvider
                ref="agendaImageUploader"
                v-slot="{ errors }"
                rules="image|size:1000|maxdimensions:942,530"
                tag="div"
              >
                <Dropzone
                  id="agendaImageDropzone"
                  accept="image/jpeg, image/png"
                  :is-error="errors.length > 0"
                  :disabled="!!imageFile"
                  @change="handleUploadImage($event)"
                >
                  <template #description>
                    <span class="mt-auto text-sm text-center text-blue-gray-300">
                      Ukuran Maksimal file upload 1 MB dengan resolusi 942 x 530. <br>
                      (.jpg dan.png )
                    </span>
                  </template>
                </Dropzone>
                <span class="font-lato text-[13px] text-red-700 mt-3">{{ errors[0] }}</span>
              </ValidationProvider>
              <transition name="slide-fade">
                <DropzoneUploadProgress
                  v-if="!!imageFile"
                  :file="imageFile"
                  :progress="imageUploadProgress"
                  :status="imageUploadStatus"
                  :image-url="form.image.file_download_uri"
                  :image-size="form.image.size"
                  class="mt-4"
                  @retry="handleRetryUpload"
                  @delete="handleDeleteImage"
                />
              </transition>
            </fieldset>

            <!-- AGENDA TITLE -->
            <fieldset class="flex flex-col p-4 mb-4 bg-white rounded-lg">
              <h2 class="mb-3 font-medium text-green-700 font-roboto">
                Judul Agenda
              </h2>
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|max:85"
                tag="div"
              >
                <JdsInputText
                  id="agenda-form__title"
                  v-model.trim="form.title"
                  placeholder="Masukkan judul agenda"
                  data-cy="agenda-form__agenda-title"
                  :error-message="errors[0]"
                />
                <p
                  v-show="errors.length === 0"
                  class="mt-2 text-xs text-gray-600"
                >
                  Tersisa {{ titleCount }} karakter
                </p>
              </ValidationProvider>
            </fieldset>

            <!-- AGENDA DESCRIPTION -->
            <fieldset class="flex flex-col p-4 mb-4 bg-white rounded-lg">
              <h2 class="mb-3 font-medium text-green-700 font-roboto">
                Deskripsi Kegiatan
              </h2>
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                tag="div"
                class="w-full"
              >
                <textarea
                  v-model.trim="form.description"
                  placeholder="Masukkan deskripsi kegiatan"
                  rows="4"
                  maxlength="155"
                  :class="{
                    'w-full border  rounded-lg px-2 py-1 bg-gray-50 hover:bg-white  focus:outline-none focus:border-green-500 focus:outline-1 focus:outline-offset-[-2px] focus:outline-yellow-500': true,
                    'border-red-700 hover:border-red-600': errors.length !== 0,
                    'border-gray-500 hover:border-green-600': errors.length === 0
                  }"
                  data-cy="agenda-form__agenda-description"
                />
                <span class="font-lato text-[13px] text-red-700 mt-3">{{ errors[0] }}</span>
                <p
                  v-show="errors.length === 0"
                  class="mt-1 text-xs text-gray-600"
                >
                  Tersisa {{ descriptionCount }} karakter
                </p>
              </ValidationProvider>
            </fieldset>

            <!-- AGENDA TYPE OFFLINE/ONLINE -->
            <fieldset class="flex flex-col p-4 mb-4 bg-white rounded-lg">
              <h2 class="mb-3 font-medium text-green-700 font-roboto">
                Pelaksanaan Acara
              </h2>
              <JdsRadioButtonGroup
                :items="types"
                placeholder-key="label"
                name="radio-button-group"
                orientation="horizontal"
                data-cy="agenda-form__type-radio-group"
                :value="form.type"
                @change="setType"
              />
            </fieldset>

            <!-- AGENDA EVENT URL -->
            <fieldset class="flex flex-col p-4 mb-4 bg-white rounded-lg">
              <h2 class="mb-3 font-medium text-green-700 font-roboto">
                Link Acara <span class="text-gray-500">(Opsional)</span>
              </h2>
              <ValidationProvider
                v-slot="{ errors }"
                rules="url"
                tag="div"
              >
                <JdsInputText
                  v-model.trim="form.url"
                  placeholder="Masukkan link kegiatan seperti zoom, google meet, dll"
                  data-cy="agenda-form__link"
                  :error-message="errors[0]"
                >
                  <template #prefix-icon>
                    <LinkIcon class="w-4 h-4 fill-gray-600" />
                  </template>
                </JdsInputText>
              </ValidationProvider>
            </fieldset>

            <!-- AGENDA WEBSITE -->
            <fieldset class="flex flex-col p-4 mb-4 bg-white rounded-lg">
              <h2 class="mb-3 font-medium text-green-700 font-roboto">
                Website <span class="text-gray-500">(Opsional)</span>
              </h2>
              <ValidationProvider
                v-slot="{ errors }"
                rules="url"
                tag="div"
              >
                <JdsInputText
                  v-model="form.website"
                  placeholder="Masukkan link website yang berhubungan dengan detail agenda"
                  data-cy="agenda-form__website"
                  :error-message="errors[0]"
                >
                  <template #prefix-icon>
                    <LinkIcon class="w-4 h-4 fill-gray-600" />
                  </template>
                </JdsInputText>
              </ValidationProvider>
            </fieldset>

            <!-- AGENDA EVENT LOCATION -->
            <fieldset
              v-show="isTypeOffline"
              class="flex flex-col p-4 mb-4 bg-white rounded-lg"
            >
              <h2 class="mb-3 font-medium text-green-700 font-roboto">
                Tempat Pelaksanaan <span class="text-gray-500">(Opsional)</span>
              </h2>
              <textarea
                v-model.trim="form.address"
                placeholder="Masukkan tempat pelaksanaan atau alamat lengkap tempat pelaksanaan"
                data-cy="agenda-form__location"
                rows="4"
                maxlength="155"
                class="w-full border border-gray-500 rounded-lg px-2 py-1 bg-gray-50 hover:bg-white hover:border-green-600 focus:outline-none focus:border-green-500 focus:outline-1 focus:outline-offset-[-2px] focus:outline-yellow-500"
              />
              <p class="mt-1 text-xs text-gray-600">
                Tersisa {{ addressCount }} karakter
              </p>
            </fieldset>

            <!-- AGENDA MAP URL -->
            <fieldset
              v-if="isTypeOffline"
              class="flex flex-col p-4 mb-4 bg-white rounded-lg"
            >
              <h2 class="mb-3 font-medium text-green-700 font-roboto">
                Google Map <span class="text-gray-500">(Opsional)</span>
              </h2>
              <ValidationProvider
                v-slot="{ errors }"
                rules="url"
                tag="div"
              >
                <JdsInputText
                  v-model.trim="form.map_url"
                  placeholder="Masukkan link google map"
                  data-cy="agenda-form__map-url"
                  :error-message="errors[0]"
                >
                  <template #prefix-icon>
                    <LinkIcon class="w-4 h-4 fill-gray-600" />
                  </template>
                </JdsInputText>
              </ValidationProvider>
            </fieldset>
          </div>

          <!-- RIGHT COLUMN -->
          <div>
            <!-- AGENDA DATE AND TIME -->
            <fieldset class="flex flex-col p-4 mb-4 bg-white rounded-lg">
              <h2 class="mb-3 font-medium text-green-700 font-roboto">
                Tanggal dan Waktu Pelaksanaan
              </h2>
              <div class="grid grid-cols-1 gap-y-4">
                <div class="grid grid-cols-2 col-span-2 gap-x-6 gap-y-1">
                  <JdsDateInput
                    v-model="form.date"
                    label="Pilih Tanggal"
                    data-cy="agenda-form__agenda-date"
                  />
                  <JdsCheckbox
                    v-model="isTodayChecked"
                    class="self-end py-[10px]"
                    text="Hari ini"
                    data-cy="agenda-form__today-checkbox"
                  />
                </div>
                <div class="grid grid-cols-2 col-span-2 gap-x-6 gap-y-1">
                  <!-- AGENDA START TIME -->
                  <div class="flex flex-col gap-1">
                    <label
                      for="start-time"
                      class="text-[15px] text-gray-800"
                    >
                      Waktu Dimulai
                    </label>
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required|timebefore:@endHour"
                      tag="div"
                      class="flex flex-col w-full"
                    >
                      <input
                        v-model="form.start_hour"
                        data-cy="agenda-form__start-time"
                        type="time"
                        class="w-full border border-gray-500 rounded-lg px-2 py-1 bg-gray-50 hover:bg-white hover:border-green-600 focus:outline-none focus:border-green-500 focus:outline-1 focus:outline-offset-[-2px] focus:outline-yellow-500"
                      >
                      <span class="font-lato text-[13px] text-red-700 mt-3">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <!-- AGENDA END TIME -->
                  <div class="flex flex-col gap-1">
                    <label
                      for="end-time"
                      class="text-[15px] text-gray-800"
                    >
                      Waktu Berakhir
                    </label>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="endHour"
                      rules="required"
                      tag="div"
                      class="flex flex-col w-full"
                    >
                      <input
                        v-model="form.end_hour"
                        data-cy="agenda-form__end-time"
                        type="time"
                        :disabled="!hasStartHour"
                        class="w-full border border-gray-500 rounded-lg px-2 py-1 bg-gray-50 hover:bg-white hover:border-green-600 focus:outline-none focus:border-green-500 focus:outline-1 focus:outline-offset-[-2px] focus:outline-yellow-500"
                        :class="{'cursor-not-allowed': !hasStartHour}"
                      >
                      <span class="font-lato text-[13px] text-red-700 mt-3">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </fieldset>

            <!-- AGENDA PINNED -->
            <Restricted permission="event.set-pin">
              <fieldset class="flex flex-col p-4 mb-4 bg-white rounded-lg">
                <h2 class="mb-3 font-medium text-green-700 font-roboto">
                  Sematkan Agenda <span class="text-gray-500">(Opsional)</span>
                </h2>
                <JdsCheckbox
                  v-model="form.is_pinned"
                  text="Sematkan"
                  class="mb-3 agenda-form__pin-agenda"
                  data-cy="agenda-form__pin-agenda"
                  :disabled="isPinDisabled"
                />
                <p class="text-sm text-gray-700 pl-7">
                  Memungkinkan Anda menandai dan meng-highlight acara atau jadwal penting agar mudah diakses
                </p>
              </fieldset>
            </Restricted>

            <!-- AGENDA DETAILS -->
            <fieldset class="flex flex-col p-4 mb-4 bg-white rounded-lg">
              <h2 class="mb-3 font-medium text-green-700 font-roboto">
                Detail Agenda
              </h2>
              <div class="flex flex-col gap-4 mb-4">
                <!-- AGENDA CATEGORY TYPE -->
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  tag="div"
                >
                  <JdsSelect
                    v-model="form.category_type"
                    label="Tipe Acara"
                    placeholder="Pilih tipe acara"
                    data-cy="agenda-form__agenda-category-type"
                    :options="categoryOptions"
                    :error-message="errors[0]"
                  />
                </ValidationProvider>

                <!-- AGENDA CATEGORY -->
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  tag="div"
                >
                  <JdsSelect
                    v-model="form.category"
                    label="Kategori Agenda"
                    placeholder="Pilih kategori"
                    data-cy="agenda-form__agenda-category"
                    :title="!hasCategoryType ? 'Pilih Tipe Acara terlebih dahulu' : null"
                    :disabled="!hasCategoryType"
                    :options="subCategoryOptions"
                    :error-message="errors[0]"
                  />
                </ValidationProvider>

                <!-- AGENDA AVAILABILITY -->
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  tag="div"
                >
                  <JdsSelect
                    v-model="form.availability"
                    label="Sifat Acara"
                    placeholder="Pilih sifat acara"
                    data-cy="agenda-form__agenda-availability"
                    :options="availabilityOptions"
                    :error-message="errors[0]"
                  />
                </ValidationProvider>
              </div>

              <div class="relative flex flex-col gap-2">
                <label
                  for="tag"
                  class="text-[15px] text-gray-800"
                >
                  Tag <span class="text-gray-500">(Opsional)</span>
                </label>
                <input
                  id="tag"
                  v-model.trim="tag"
                  autocomplete="off"
                  data-cy="agenda-form__tag-input"
                  class="border border-gray-500 rounded-lg px-2 py-1 placeholder:text-gray-600 text-gray-600 bg-gray-50 hover:bg-white hover:border-green-600 focus:outline-none focus:border-green-500 focus:outline-1 focus:outline-offset-[-2px] focus:outline-yellow-500"
                  placeholder="Ketikkan tag disini lalu tekan enter"
                  @keyup.enter.stop="onTagInputEnter()"
                >
                <div
                  v-show="hasTagSuggestions"
                  class="absolute w-full mt-[72px] z-20"
                >
                  <JdsOptions
                    class="w-full"
                    data-cy="agenda-form__tag-suggestions"
                    :options="tagSuggestions"
                    @click:option="onTagSuggestionsClick"
                  />
                </div>
                <div
                  class="border border-gray-500 overflow-y-auto rounded-lg p-2 h-[88px] text-gray-600 bg-gray-50 hover:bg-white hover:border-green-600 focus:outline-none focus:border-green-500 focus:outline-1 focus:outline-offset-[-2px] focus:outline-yellow-500"
                  data-cy="agenda-form__tags-container"
                >
                  <div
                    v-if="hasTags"
                    class="flex flex-wrap gap-1"
                  >
                    <div
                      v-for="(item, index) in form.tags"
                      :key="index"
                      class="bg-gray-200 text-gray-700 text-sm rounded-3xl px-[10px] py-[6px] flex items-center justify-center gap-1"
                      :data-cy="`agenda-form__tag-item-${index + 1}`"
                    >
                      {{ item.tag_name }}
                      <JdsIcon
                        name="times"
                        size="12px"
                        class="pt-[2px] cursor-pointer"
                        :data-cy="`agenda-form__tag-item-${index + 1}-delete-button`"
                        @click="removeTag(index)"
                      />
                    </div>
                  </div>
                  <p v-else>
                    Belum ada tag
                  </p>
                </div>
              </div>
            </fieldset>
          </div>
        </section>
      </form>
    </ValidationObserver>

    <AgendaPreview
      :open="modalState === 'PREVIEW'"
      :event="form"
      @close="closeModal"
    />

    <BaseModal :open="modalState === 'SUCCESS' || modalState === 'ERROR'">
      <div class="w-full h-full px-2 pb-4">
        <h1 class="font-roboto font-medium text-green-700 text-[21px] leading-[34px] mb-6">
          {{ modalConfig.title }}
        </h1>
        <div class="flex items-center gap-4">
          <JdsIcon
            :name="modalConfig.icon.name"
            :class="modalConfig.icon.class"
          />
          <p class="text-sm leading-6 to-blue-gray-800">
            {{ modalConfig.body }}
          </p>
        </div>
      </div>
      <template #footer>
        <div class="flex items-center justify-center w-full h-full gap-4 p-2">
          <BaseButton
            class="text-sm text-white bg-green-700 hover:bg-green-600"
            @click="modalConfig.action"
          >
            Saya Mengerti
          </BaseButton>
        </div>
      </template>
    </BaseModal>

    <BaseModal :open="modalState === 'CONFIRMATION'">
      <div class="w-full h-full px-2 pb-4">
        <h1 class="font-roboto font-medium text-green-700 text-[21px] leading-[34px] mb-6">
          Simpan Agenda
        </h1>
        <div class="flex items-center gap-4">
          <p class="text-sm leading-6 to-blue-gray-800">
            Apakah Anda ingin menyimpan agenda ini terlebih dahulu?
          </p>
        </div>
      </div>
      <template #footer>
        <div class="flex items-center justify-end w-full h-full gap-4 p-2">
          <BaseButton
            class="text-sm text-green-700 border border-green-700 hover:bg-green-50"
            @click="onCancel"
          >
            Tidak perlu simpan
          </BaseButton>
          <BaseButton
            class="text-sm text-white bg-green-700 hover:bg-green-600"
            @click="onConfirm"
          >
            Ya, simpan agenda
          </BaseButton>
        </div>
      </template>
    </BaseModal>

    <ProgressModal
      :open="modalState === 'LOADING'"
      :value="modalProgress"
    />
  </main>
</template>

<script>
import '@/common/helpers/vee-validate.js';
import { AGENDA_V2_CATEGORIES } from '@/common/constants';
import debounce from 'lodash.debounce';
import { formatDate, isToday } from '@/common/helpers/date';
import { delay } from '@/common/helpers/delay';
import { EventBus } from '@/common/helpers/event-bus';
import HeaderMenu from '@/common/components/HeaderMenu';
import BaseButton from '@/common/components/BaseButton';
import BaseModal from '@/common/components/BaseModal';
import ProgressModal from '@/common/components/ProgressModal';
import AgendaPreview from '@/components/AgendaV2/AgendaPreview.vue';
import SaveIcon from '@/assets/icons/save.svg?inline';
import LinkIcon from '@/assets/icons/link.svg?inline';
import Dropzone from '@/common/components/Dropzone';
import DropzoneUploadProgress from '@/common/components/DropzoneUploadProgress';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
import Restricted from '@/common/components/Restricted';

const agendaRepository = RepositoryFactory.get('agenda');
const tagRepository = RepositoryFactory.get('tag');
const mediaRepository = RepositoryFactory.get('media');

const MODAL_STATE = Object.freeze({
  NONE: 'NONE',
  LOADING: 'LOADING',
  PREVIEW: 'PREVIEW',
  CONFIRMATION: 'CONFIRMATION',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
});

const IMAGE_UPLOAD_STATUS = Object.freeze({
  NONE: 'NONE',
  UPLOADING: 'UPLOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  HASDEFAULT: 'HASDEFAULT',
});

const TYPE_OPTIONS = [
  { label: 'Offline', value: 'offline' },
  { label: 'Online', value: 'online' },
];

const AVAILABILITY_OPTIONS = [
  {
    value: 'TERBUKA',
    label: 'Terbuka',
  },
  {
    value: 'TERTUTUP',
    label: 'Tertutup',
  },
];

const TITLE_CHARACTER_COUNT = 85;
const DESCRIPTION_CHARACTER_COUNT = 155;
const ADDRESS_CHARACTER_COUNT = 155;

export default {
  name: 'CreateEditAgenda',
  components: {
    HeaderMenu,
    BaseButton,
    BaseModal,
    AgendaPreview,
    SaveIcon,
    LinkIcon,
    ProgressModal,
    Dropzone,
    DropzoneUploadProgress,
    Restricted,
    ValidationObserver,
    ValidationProvider,
  },
  beforeRouteLeave(to, _, next) {
    this.targetRoute = to?.path || '/';
    const { invalid, pristine } = this.$refs.agendaForm?.flags || {};

    if (invalid || pristine || this.isFormSubmitted || this.isConfirmedToLeave) {
      next();
    } else {
      this.setModalStatus(MODAL_STATE.CONFIRMATION);
      next(false);
    }
  },
  data() {
    return {
      form: {
        image: {
          file_name: '',
          file_download_uri: '',
          size: 0,
        },
        title: '',
        description: '',
        type: 'offline',
        url: '',
        website: '',
        address: '',
        map_url: '',
        date: formatDate(new Date(), 'dd/MM/yyyy'),
        start_hour: '',
        end_hour: '',
        category_type: '',
        category: '',
        availability: '',
        tags: [],
        is_pinned: false,
      },
      imageFile: null,
      imageUploadStatus: IMAGE_UPLOAD_STATUS.NONE,
      imageUploadProgress: 0,
      types: TYPE_OPTIONS,
      availabilityOptions: AVAILABILITY_OPTIONS,
      tag: '',
      tagSuggestions: [],
      isTodayChecked: true,
      modalState: MODAL_STATE.NONE,
      modalConfig: {
        title: '',
        body: '',
        icon: {
          name: '',
          class: '',
        },
        action: null,
      },
      modalProgress: 0,
      targetRoute: null,
      isFormSubmitted: false,
      isConfirmedToLeave: false,
      isMaximumPinned: false,
      isOriginalDataPinned: false,
    };
  },
  computed: {
    mode() {
      return this.$route.meta?.mode || 'create';
    },
    isEditMode() {
      return this.mode === 'edit';
    },
    submitButtonLabel() {
      return this.isEditMode ? 'Simpan Perubahan' : 'Tambah Agenda';
    },
    isTypeOffline() {
      return this.form.type === 'offline';
    },
    hasStartHour() {
      return !!this.form.start_hour;
    },
    hasEndHour() {
      return !!this.form.end_hour;
    },
    hasCategoryType() {
      return !!this.form.category_type;
    },
    hasTags() {
      return Array.isArray(this.form.tags) && !!this.form.tags.length;
    },
    hasTagSuggestions() {
      return this.tagSuggestions.length > 0;
    },
    today() {
      return new Date().setHours(0, 0, 0, 0);
    },
    selectedDate() {
      const date = this.form.date.split('/');
      const year = date[2];
      // month is zero based, we need to subtract 1
      const month = date[1] - 1;
      const day = date[0];

      return new Date(year, month, day);
    },
    titleCount() {
      return TITLE_CHARACTER_COUNT - this.form.title.length;
    },
    descriptionCount() {
      return DESCRIPTION_CHARACTER_COUNT - this.form.description.length;
    },
    addressCount() {
      return ADDRESS_CHARACTER_COUNT - this.form.address.length;
    },
    categoryOptions() {
      return Object.keys(AGENDA_V2_CATEGORIES);
    },
    subCategoryOptions() {
      if (!this.hasCategoryType) return [];

      return AGENDA_V2_CATEGORIES[this.form.category_type] ?? [];
    },
    isPinDisabled() {
      if (this.mode === 'create') {
        return this.isMaximumPinned;
      }

      if (this.mode === 'edit' && this.isOriginalDataPinned) {
        return false;
      }

      return this.isMaximumPinned;
    },
  },
  watch: {
    isTodayChecked() {
      if (this.isTodayChecked) {
        this.setDate(formatDate(this.today, 'dd/MM/yyyy'));
      }
    },
    'form.date': function () {
      this.isTodayChecked = isToday(this.selectedDate);
    },
    'form.category_type': function (newState, prevState) {
      if (prevState !== '' && newState !== prevState) {
        this.form.category = '';
      }
    },
    tag() {
      if (this.tag) {
        this.getTagSuggestions();
      } else {
        this.clearTagSuggestions();
      }
    },
  },
  created() {
    const isAgendaV2Enabled = this.$store.state.featureFlag.agendaV2;

    if (!isAgendaV2Enabled) {
      this.$router.replace('/agenda');
    }
  },
  async mounted() {
    // Set Agenda Title Max Length
    const titleInput = document.querySelector('#agenda-form__title input');
    titleInput.setAttribute('maxlength', 85);

    if (this.isEditMode) {
      this.setInitialData();
    }

    try {
      const { pinned, maxPinned } = await this.getPinnedCount();
      this.isMaximumPinned = pinned >= maxPinned;
    } catch (error) {
      this.$toast({
        type: 'error',
        message: 'Gagal mendapatkan data sematan',
      });
      console.error('[ERROR - AGENDA v2 FORM] Failed to check pinned agenda', error);
    }
  },
  methods: {
    isEmpty(string) {
      return string === '';
    },
    async setInitialData() {
      try {
        const { id } = this.$route.params;
        const response = await agendaRepository.getEventById(id);
        const { data } = response.data;
        this.form = {
          image: {
            file_name: data.image?.file_name ?? '',
            file_download_uri: data.image?.file_download_uri ?? '',
            size: data.image?.size ?? 0,
          },
          title: data.title ?? '',
          description: data.description ?? '',
          type: data.type ?? '',
          url: data.url ?? '',
          website: data.website ?? '',
          address: data.address ?? '',
          map_url: data.map_url ?? '',
          date: data.date ? formatDate(data.date, 'dd/MM/yyyy') : formatDate(new Date(), 'dd/MM/yyyy'),
          start_hour: data.start_hour ?? '',
          end_hour: data.end_hour ?? '',
          is_pinned: data.is_pinned ?? false,
          category_type: data.category_type ?? '',
          category: data.category ?? '',
          availability: data.availability ?? '',
          tags: data.tags ?? [],
        };
        this.isOriginalDataPinned = data.is_pinned ?? false;
        this.imageUploadStatus = IMAGE_UPLOAD_STATUS.HASDEFAULT;
        this.imageFile = new File([''], data.image?.file_name ?? '');
      } catch (error) {
        if (error.response?.status === 403) {
          EventBus.$emit('error:forbidden');
        }
      }
    },
    setType(type) {
      this.form.type = type;
    },
    setDate(date) {
      this.form.date = date;
    },
    getTagSuggestions: debounce(async function () {
      try {
        const response = await tagRepository.getTagSuggestions({ q: this.tag });
        const tagSuggestions = response.data.map((tag) => tag.name).slice(0, 5);
        this.setTagSuggestions(tagSuggestions);
      } catch (error) {
        this.clearTagSuggestions();
      }
    }, 500),
    onTagSuggestionsClick(tag) {
      this.setTags(tag);
      this.clearTag();
      this.clearTagSuggestions();
    },
    setTagSuggestions(tagSuggestions) {
      this.tagSuggestions = tagSuggestions;
    },
    clearTagSuggestions() {
      this.tagSuggestions = [];
    },
    setTags(tag) {
      this.form.tags.push({ tag_name: tag });
    },
    removeTag(index) {
      this.form.tags.splice(index, 1);
    },
    clearTag() {
      this.tag = '';
    },
    onTagInputEnter() {
      const tag = this.tag.trim().split(' ').join('-').toLowerCase();
      if (!this.isEmpty(tag)) {
        this.setTags(tag);
        this.clearTag();
      }
    },
    async onSubmit() {
      this.setModalStatus(MODAL_STATE.LOADING);
      this.setModalProgress(20);

      const data = {
        ...this.form,
        date: formatDate(this.selectedDate, 'yyyy-MM-dd'),
        url: this.form.url || null,
        website: this.form.website || null,
        map_url: this.form.map_url || null,
        tags: this.form.tags.map((tag) => tag.tag_name),
      };

      if (this.isEditMode) {
        this.updateEvent(data);
      } else {
        this.createEvent(data);
      }
    },
    async createEvent(data) {
      await delay(100);
      this.setModalProgress(50);

      /**
       * Check if user allowed to add pinned Agenda.
       * User only allowed to add new pinned Agenda if current pinned
       * not exceeding max capacity.
       */
      try {
        const { pinned, maxPinned } = await this.getPinnedCount();

        if (data.is_pinned && pinned >= maxPinned) {
          await delay(100);

          this.setErrorModal({
            title: 'Tambah Agenda Gagal',
            body: 'Agenda yang disematkan sudah melebihi kapasitas',
            action: this.closeModal,
          });

          return;
        }
      } catch (error) {
        await delay(100);

        this.setErrorModal({
          title: 'Tambah Agenda Gagal',
          body: 'Agenda yang Anda buat gagal ditambahkan.',
          action: this.closeModal,
        });

        console.error('[ERROR - AGENDA v2 FORM] Failed to check pinned agenda', error);
        return;
      }

      try {
        await agendaRepository.createEvent(data);

        await delay(100);
        this.setModalProgress(75);
        await delay(100);

        this.setSuccessModal({
          title: 'Tambah Agenda Berhasil',
          body: 'Agenda yang Anda buat berhasil ditambahkan.',
          action: () => { this.$router.push('/agenda-v2'); },
        });

        this.isFormSubmitted = true;
      } catch (error) {
        await delay(100);
        this.setModalProgress(75);
        await delay(100);

        this.setErrorModal({
          title: 'Tambah Agenda Gagal',
          body: 'Agenda yang Anda buat gagal ditambahkan.',
          action: this.closeModal,
        });
      }
    },
    async updateEvent(data) {
      await delay(100);
      this.setModalProgress(50);

      try {
        const { id } = this.$route.params;
        await agendaRepository.updateEvent(id, data);

        await delay(100);
        this.setModalProgress(75);
        await delay(100);

        this.setSuccessModal({
          title: 'Simpan Agenda Berhasil',
          body: 'Agenda yang Anda buat berhasil disimpan.',
          action: () => { this.$router.push('/agenda-v2'); },
        });

        this.isFormSubmitted = true;
      } catch (error) {
        await delay(100);
        this.setModalProgress(75);
        await delay(100);

        this.setErrorModal({
          title: 'Simpan Agenda Gagal',
          body: 'Agenda yang Anda buat gagal disimpan.',
          action: this.closeModal,
        });
      }
    },
    async getPinnedCount() {
      try {
        const response = await agendaRepository.getPinnedCount();
        const { data } = response.data;

        return Promise.resolve({
          pinned: data.pinned,
          maxPinned: data.max_pinned,
        });
      } catch (error) {
        return Promise.reject(new Error(error));
      }
    },
    async onConfirm() {
      this.resetModal();
      await delay(300);
      this.onSubmit();
    },
    onCancel() {
      this.resetModal();
      this.isConfirmedToLeave = true;
      this.$router.push(this.targetRoute);
    },
    async handleUploadImage(file) {
      const { valid } = await this.$refs.agendaImageUploader.validate(file);

      if (valid) {
        this.uploadImage(file);
      }
    },
    handleRetryUpload() {
      this.handleUploadImage(this.imageFile);
    },
    async handleDeleteImage() {
      try {
        await this.deleteUploadedImage(this.form.image.file_download_uri);
        this.resetImageState();

        this.$toast({
          type: 'success',
          message: 'Berhasil menghapus media',
        });
      } catch (error) {
        this.$toast({
          type: 'error',
          message: 'Gagal menghapus media!',
        });
      }
    },
    async uploadImage(file) {
      const formData = new FormData();
      formData.append('file', file, file.name);

      try {
        this.imageFile = file;
        this.imageUploadProgress = 0;
        this.imageUploadStatus = IMAGE_UPLOAD_STATUS.UPLOADING;

        const response = await mediaRepository.uploadMediaWithProgress(formData, (progress) => {
          this.imageUploadProgress = progress;
        }, { domain: 'agenda' });

        this.imageUploadStatus = IMAGE_UPLOAD_STATUS.SUCCESS;

        if (response.status === 201) {
          const { data } = response;
          this.form.image.file_download_uri = data.file_download_uri;
          this.form.image.file_name = data.file_name;
          this.form.image.size = data.size;
        }
      } catch (error) {
        console.error('[ERROR - AGENDA v2 FORM] Failed to upload image. ', error);
        this.imageUploadStatus = IMAGE_UPLOAD_STATUS.ERROR;
        this.form.image.file_download_uri = '';
        this.form.image.file_name = '';
        this.form.image.size = 0;
      }
    },
    async deleteUploadedImage(fileURI) {
      try {
        const response = await mediaRepository.deleteMedia({
          key: fileURI,
          domain: 'agenda',
        });
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(new Error(error));
      }
    },
    resetImageState() {
      this.form.image.file_download_uri = '';
      this.form.image.file_name = '';
      this.form.image.size = 0;
      this.imageFile = null;
      this.imageUploadStatus = IMAGE_UPLOAD_STATUS.NONE;
      this.imageUploadProgress = 0;
      // reset input type file value
      document.querySelector('#agendaImageDropzone input').value = '';
    },
    setModalStatus(status) {
      this.modalState = status;
    },
    setModalProgress(progress) {
      this.modalProgress = progress;
    },
    setSuccessModal({ title, body, action }) {
      this.setModalStatus(MODAL_STATE.SUCCESS);
      this.modalConfig.title = title;
      this.modalConfig.body = body;
      this.modalConfig.icon.name = 'check-mark-circle';
      this.modalConfig.icon.class = 'text-green-600';
      this.modalConfig.action = action;
    },
    setErrorModal({ title, body, action }) {
      this.setModalStatus(MODAL_STATE.ERROR);
      this.modalConfig.title = title;
      this.modalConfig.body = body;
      this.modalConfig.icon.name = 'warning';
      this.modalConfig.icon.class = 'text-red-600';
      this.modalConfig.action = action;
    },
    async closeModal() {
      this.setModalStatus(MODAL_STATE.NONE);
      await delay(300);
      this.resetModal();
    },
    resetModal() {
      this.modalState = MODAL_STATE.NONE;
      this.modalConfig.title = '';
      this.modalConfig.body = '';
      this.modalConfig.icon.name = '';
      this.modalConfig.icon.class = '';
      this.modalConfig.action = null;
      this.modalProgress = 0;
    },
  },
};
</script>

<style scoped>
.agenda__form::v-deep .jds-popover,
.agenda__form::v-deep .jds-date-input {
  width: 100%;
}
.agenda__form::v-deep .jds-form-control-label {
  margin-bottom: 4px !important;
}
.agenda__form::v-deep .jds-form-control-error-message {
  margin-top: 4px !important
}
.agenda__form::v-deep .jds-select,
.agenda__form::v-deep .jds-popover__activator,
.agenda__form::v-deep .jds-input-text {
  width: 100% !important;
}
.agenda__form::v-deep .jds-select__error-message {
  position: relative !important;
}
.agenda__form::v-deep .jds-options__option-list {
  max-height: 200px;
  overflow-y: auto;
}
.agenda__form::v-deep .jds-text-area__input-wrapper > textarea {
  border: 1px solid #9E9E9E;
}
.agenda__form::v-deep .jds-text-area__input-wrapper > textarea:hover {
  border: 1px solid #16a34a;
}
.agenda__form::v-deep .jds-popover__content {
  background-color: white;
  z-index: 10 !important;
}
.agenda__form::v-deep .jds-calendar {
  max-width: none !important;
}
.agenda__form::v-deep .jds-calendar .jds-calendar__list-of-days,
.agenda__form::v-deep .jds-calendar .jds-calendar__days {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr) !important;
}
.agenda-form__pin-agenda[disabled] {
  cursor: not-allowed !important;
}
.agenda-form__pin-agenda[disabled]::v-deep .jds-checkbox__checkbox-wrapper {
  pointer-events: none !important;
}
.agenda-form__pin-agenda[disabled]::v-deep .jds-checkbox-toggle__icon-wrapper {
  background: #eeeeee !important;
}
</style>
