<template>
  <BaseModal
    ref="agenda-preview"
    :open="open"
    @close="$emit('close')"
  >
    <div class="w-full h-full max-w-2xl">
      <h1
        ref="agenda-preview-title"
        class="mb-4 text-xl font-medium leading-8 text-green-700 font-roboto"
        data-cy="agenda-preview__title"
      >
        {{ event.title || '-' }}
      </h1>
      <section
        ref="agenda-preview-body"
        class="grid w-full grid-cols-2 pb-8"
        data-cy="agenda-preview__body"
      >
        <div
          ref="agenda-preview-image"
          class="col-span-2 mb-6 bg-gray-200 rounded-md overflow-clip aspect-w-16 aspect-h-9"
          data-cy="agenda-image-container"
        >
          <img
            :src="event.image?.file_download_uri ?? ''"
            :alt="event.title"
            :title="event.title"
            class="object-contain object-center w-full h-full"
          >
        </div>

        <div
          ref="agenda-preview-event-description"
          class="flex col-span-2 gap-2 mb-6"
          data-cy="agenda-preview__description-container"
        >
          <InfoIcon class="w-4 h-4 fill-green-700 flex-shrink-0" />
          <div class="flex flex-col gap-1">
            <h2 class="text-xs text-blue-gray-200">
              Deskripsi
            </h2>
            <div
              class="text-sm text-gray-800"
              :title="event.description"
              data-cy="agenda-preview__description"
            >
              {{ event.description || '-' }}
            </div>
          </div>
        </div>

        <div
          ref="agenda-preview-category"
          class="flex gap-2 mb-6"
          data-cy="agenda-preview__category-container"
        >
          <CategoryIcon class="w-4 h-4 fill-green-700 flex-shrink-0" />
          <div class="flex flex-col gap-1">
            <h2 class="text-xs text-blue-gray-200">
              Kategori Event
            </h2>
            <div
              class="px-4 py-1 text-sm text-green-700 rounded-lg w-fit bg-green-50 line-clamp-1"
              :title="event.category"
              data-cy="agenda-preview__category"
            >
              {{ event.category || '-' }}
            </div>
          </div>
        </div>

        <div
          ref="agenda-preview-event-type"
          class="mb-6"
          data-cy="agenda-preview__type-container"
        >
          <div class="flex flex-col gap-1">
            <h2 class="text-xs text-blue-gray-200">
              Tipe Event
            </h2>
            <div
              class="px-4 py-1 text-sm capitalize rounded-lg w-fit line-clamp-1"
              :class="{
                'text-green-700 bg-green-50': event.type === 'online',
                'text-pink-700 bg-pink-50': event.type === 'offline',
              }"
              :title="event.type"
              data-cy="agenda-preview__type"
            >
              {{ event.type || '-' }}
            </div>
          </div>
        </div>

        <div
          ref="agenda-preview-event-availability"
          class="flex gap-2 mb-6"
          data-cy="agenda-preview__availability-container"
        >
          <InfoIcon class="w-4 h-4 fill-green-700 flex-shrink-0" />
          <div class="flex flex-col gap-1">
            <h2 class="text-xs text-blue-gray-200">
              Sifat Acara
            </h2>
            <div
              class="px-4 py-1 text-sm text-gray-700 capitalize bg-gray-100 rounded-lg w-fit line-clamp-1"
              :title="event.availability"
              data-cy="agenda-preview__availability"
            >
              {{ event.availability?.toLowerCase() || '-' }}
            </div>
          </div>
        </div>

        <div
          ref="agenda-preview-event-category-type"
          class="mb-6"
          data-cy="agenda-preview__category-type-container"
        >
          <div class="flex flex-col gap-1">
            <h2 class="text-xs text-blue-gray-200">
              Tipe Acara
            </h2>
            <div
              class="px-4 py-1 text-sm text-gray-700 bg-gray-100 rounded-lg w-fit line-clamp-1"
              :title="event.category_type"
              data-cy="agenda-preview__category-type"
            >
              {{ event.category_type || '-' }}
            </div>
          </div>
        </div>

        <div
          ref="agenda-preview-date"
          class="flex gap-2 mb-6"
          data-cy="agenda-preview__date-container"
        >
          <CalendarIcon class="w-4 h-4 fill-green-700 flex-shrink-0" />
          <div class="flex flex-col gap-1">
            <h2 class="text-xs text-blue-gray-200">
              Tanggal
            </h2>
            <p
              class="text-sm text-gray-800 capitalize line-clamp-1"
              data-cy="agenda-preview__date"
            >
              {{ date }}
            </p>
          </div>
        </div>

        <div
          ref="agenda-preview-time"
          class="mb-6"
          data-cy="agenda-preview__time-container"
        >
          <div class="flex flex-col gap-1">
            <h2 class="text-xs text-blue-gray-200">
              Waktu
            </h2>
            <p
              class="text-sm text-gray-800 line-clamp-1"
              data-cy="agenda-preview__time"
            >
              {{ time }}
            </p>
          </div>
        </div>

        <div
          ref="agenda-preview-website"
          class="flex col-span-2 gap-2 mb-6"
          data-cy="agenda-preview__website-container"
        >
          <SiteIcon class="w-4 h-4 fill-green-700 flex-shrink-0" />
          <div class="flex flex-col gap-1">
            <h2 class="text-xs text-blue-gray-200">
              Website
            </h2>
            <a
              v-if="event.website"
              :href="event.website"
              target="_blank"
              rel="noopener noreferrer"
              class="text-blue-500 underline hover:text-blue-400"
              data-cy="agenda-preview__website"
            >
              {{ event.website }}
            </a>
            <p
              v-else
              class="text-sm text-gray-800"
            >
              -
            </p>
          </div>
        </div>

        <div
          ref="agenda-preview-link"
          class="flex col-span-2 gap-2 mb-6"
          data-cy="agenda-preview__type-online-container"
        >
          <LinkIcon class="w-4 h-4 fill-green-700 flex-shrink-0" />
          <div class="flex flex-col gap-1">
            <h2 class="text-xs text-blue-gray-200">
              Link Kegiatan
            </h2>
            <a
              v-if="event.url"
              :href="event.url"
              target="_blank"
              rel="noopener noreferrer"
              class="text-blue-500 underline hover:text-blue-400"
              data-cy="agenda-preview__link"
            >
              {{ event.url }}
            </a>
            <p
              v-else
              class="text-sm text-gray-800"
            >
              -
            </p>
          </div>
        </div>

        <div
          v-show="event.type === 'offline'"
          ref="agenda-preview-address"
          class="flex col-span-2 gap-2 mb-6"
          data-cy="agenda-preview__type-offline-container"
        >
          <LocationIcon class="w-4 h-4 fill-green-700 flex-shrink-0" />
          <div class="flex flex-col gap-1">
            <h2 class="text-xs text-blue-gray-200">
              Tempat Pelaksanaan
            </h2>
            <p
              class="text-sm text-gray-800 capitalize"
              data-cy="agenda-preview__address"
            >
              {{ event.address || '-' }}
            </p>
          </div>
        </div>

        <div
          v-show="event.type === 'offline'"
          ref="agenda-preview-map-url"
          class="flex col-span-2 gap-2 mb-6"
          data-cy="agenda-preview__map-url-container"
        >
          <LocationIcon class="w-4 h-4 fill-green-700 flex-shrink-0" />
          <div class="flex flex-col gap-1">
            <h2 class="text-xs text-blue-gray-200">
              Google Map
            </h2>
            <a
              v-if="event.map_url"
              :href="event.map_url"
              target="_blank"
              rel="noopener noreferrer"
              class="text-blue-500 underline hover:text-blue-400"
              data-cy="agenda-preview__map-url"
            >
              {{ event.map_url }}
            </a>
            <p
              v-else
              class="text-sm text-gray-800"
            >
              -
            </p>
          </div>
        </div>

        <div
          v-show="hasTags"
          ref="agenda-preview-tags"
          class="flex col-span-2 gap-2"
          data-cy="agenda-preview__tags-container"
        >
          <TagIcon class="w-4 h-4 fill-green-700 flex-shrink-0" />
          <div class="flex flex-col gap-1">
            <h2 class="text-xs text-blue-gray-200">
              Tags
            </h2>
            <div
              class="flex flex-wrap items-center min-w-0 gap-2"
            >
              <span
                v-for="(tag, index) in event.tags"
                :key="tag.id"
                class="px-[10px] py-[6px] rounded-full bg-gray-200 text-gray-700 text-sm"
                :data-cy="`agenda-preview__tag-item-${index + 1}`"
              >
                {{ tag.tag_name }}
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/common/components/BaseModal';
import CategoryIcon from '@/assets/icons/category.svg?inline';
import CalendarIcon from '@/assets/icons/calendar.svg?inline';
import LinkIcon from '@/assets/icons/link.svg?inline';
import LocationIcon from '@/assets/icons/marker.svg?inline';
import TagIcon from '@/assets/icons/tag.svg?inline';
import SiteIcon from '@/assets/icons/site.svg?inline';
import InfoIcon from '@/assets/icons/info.svg?inline';
import { formatDate } from '@/common/helpers/date';

export default {
  name: 'AgendaPreview',
  components: {
    BaseModal,
    CategoryIcon,
    CalendarIcon,
    LinkIcon,
    LocationIcon,
    TagIcon,
    SiteIcon,
    InfoIcon,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
    event: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    time() {
      const startTime = this.event?.start_hour || null;
      const endTime = this.event?.end_hour || null;

      if (startTime && endTime) {
        return `${startTime.substring(0, 5)} sampai ${endTime.substring(0, 5)} WIB`;
      }

      return '-';
    },
    date() {
      if (this.event?.date) {
        const { date } = this.event;
        /**
         * `this.event.date` is already formatted to dd/MM/YYYY (id-ID locale)
         *  so we need to convert it back to YYYY/MM/dd before reformatting it
         */
        const reversedDate = date.split('/').reverse().join('/');
        return formatDate(reversedDate, 'EEEE, dd MMM yyyy');
      }

      return '-';
    },
    hasTags() {
      return this.event?.tags && !!this.event?.tags.length;
    },
  },
};
</script>
