<template>
  <main class="w-full pb-20">
    <section class="px-3 py-6 rounded-lg bg-white border-2 border-green-600">
      <div class="w-full">
        <!-- Table Menu -->
        <div class="sm:flex-wrap flex mb-4 items-start justify-between">
          <div class="flex flex-row flex-wrap items-center">
            <SearchBar
              class="mb-3"
              placeholder="Cari agenda"
              @input="onSearch($event)"
            />
            <AgendaFilter
              class="sm:ml-0 lg:ml-6"
              :params="params"
              @change:filter="onChangeFilter($event)"
            />
          </div>
          <div class="flex flex-row gap-2">
            <Restricted permission="event.set-pin">
              <AgendaPinned
                class="mt-[2px]"
                :pinned="pinnedCount.pinned"
                :max-pinned="pinnedCount.maxPinned"
              />
            </Restricted>
            <LinkButton
              href="agenda-v2/tambah"
              title="Tambah Agenda"
              data-cy="agenda__add-button"
            >
              <template #icon-left>
                <JdsIcon
                  name="plus"
                  size="14px"
                  fill="#fff"
                  class="h-[14px] w-[14px]"
                />
              </template>
              <p class="font-lato font-bold text-sm text-white leading-none">
                Tambah Agenda
              </p>
            </LinkButton>
          </div>
        </div>
        <div class="w-full overflow-auto">
          <AgendaTable
            :items="items"
            :loading="loading"
            :meta="meta"
            class="min-w-[1000px]"
            @update:pagination="onUpdatePagination($event)"
            @open-preview="getAgendaById($event)"
            @pin-agenda="setPinAgenda($event)"
            @unpin-agenda="setUnpinAgenda($event)"
            @delete="handleDeleteAction($event)"
            @change:sort="onChangeSort($event)"
          />
        </div>
      </div>
    </section>
    <AgendaPreview
      :open="isPreviewModalOpen"
      :event="eventDetail"
      @close="closePreviewModal"
    />

    <!-- Delete Action Prompt -->
    <BaseModal
      :open="isDeletePromptOpen"
      @close="toggleDeletePrompt"
    >
      <div class="w-full h-full">
        <h1 class="font-roboto text-xl leading-8 font-medium text-green-700 mb-6">
          Hapus Agenda
        </h1>
        <p class="font-lato text-sm text-gray-800 mb-2">
          Apakah Anda yakin akan menghapus agenda ini?
        </p>
        <h2 class="font-lato text-md font-bold text-gray-800">
          {{ eventDetail.title }}
        </h2>
      </div>
      <template #footer>
        <div class="flex gap-4 justify-end">
          <BaseButton
            class="border-green-700 hover:bg-green-50 text-sm text-green-700"
            data-cy="agenda__modal-button--cancel"
            @click="closeDeletePrompt"
          >
            Batal
          </BaseButton>
          <BaseButton
            class="bg-red-500 hover:bg-red-400 text-sm text-white"
            :disabled="deleteLoading"
            data-cy="agenda__modal-button--delete"
            @click="deleteEvent(eventDetail.id)"
          >
            <p v-if="!deleteLoading">
              Ya, saya yakin
            </p>
            <p
              v-else
              class="flex gap-2 items-center text-gray-500"
            >
              <JdsSpinner
                size="16"
                foreground="#757575"
              />
              Loading...
            </p>
          </BaseButton>
        </div>
      </template>
    </BaseModal>

    <!-- Pin Agenda Action Modal -->
    <BaseModal
      :open="isPinModalOpen"
      @close="togglePinPrompt"
    >
      <div class="w-full h-full">
        <h1 class="font-roboto text-xl leading-8 font-medium text-green-700 mb-6">
          Sematkan Agenda
        </h1>
        <p class="font-lato text-sm text-gray-800 mb-2">
          Apakah Anda ingin menyoroti agenda ini dengan sematkan Agenda?
        </p>
        <h2 class="font-lato text-md font-bold text-gray-800">
          {{ eventDetail.title }}
        </h2>
      </div>
      <template #footer>
        <div class="flex gap-4 justify-end">
          <BaseButton
            class="border-green-700 hover:bg-green-50 text-sm text-green-700"
            data-cy="agenda__modal-button--cancel"
            @click="togglePinPrompt"
          >
            Tidak
          </BaseButton>
          <BaseButton
            class="bg-green-500 hover:bg-green-400 text-sm text-white"
            :disabled="deleteLoading"
            data-cy="agenda__modal-button--delete"
            @click="updatePinAgenda(eventDetail)"
          >
            <p v-if="!deleteLoading">
              Ya, sematkan agenda
            </p>
            <p
              v-else
              class="flex gap-2 items-center text-gray-500"
            >
              <JdsSpinner
                size="16"
                foreground="#757575"
              />
              Loading...
            </p>
          </BaseButton>
        </div>
      </template>
    </BaseModal>

    <!-- Unpin Agenda Action Modal -->
    <BaseModal
      :open="isUnpinModalOpen"
      @close="closeUnpinModal"
    >
      <div class="w-full h-full">
        <h1 class="font-roboto text-xl leading-8 font-medium text-green-700 mb-6">
          Batalkan Sematan
        </h1>
        <p class="font-lato text-sm text-gray-800 mb-2">
          Apakah Anda yakin akan lepaskan sematan agenda ini?
        </p>
        <h2 class="font-lato text-md font-bold text-gray-800">
          {{ eventDetail.title }}
        </h2>
      </div>
      <template #footer>
        <div class="flex gap-4 justify-end">
          <BaseButton
            class="border-green-700 hover:bg-green-50 text-sm text-green-700"
            data-cy="agenda__modal-button--cancel"
            @click="closeUnpinModal"
          >
            Batal
          </BaseButton>
          <BaseButton
            class="bg-red-500 hover:bg-red-400 text-sm text-white"
            :disabled="deleteLoading"
            data-cy="agenda__modal-button--delete"
            @click="updateUnpinAgenda(eventDetail)"
          >
            <p v-if="!deleteLoading">
              Ya, batalkan sematan
            </p>
            <p
              v-else
              class="flex gap-2 items-center text-gray-500"
            >
              <JdsSpinner
                size="16"
                foreground="#757575"
              />
              Loading...
            </p>
          </BaseButton>
        </div>
      </template>
    </BaseModal>
  </main>
</template>

<script>
import { computed } from 'vue';
import AgendaTable from '@/components/AgendaV2/AgendaTable.vue';
import AgendaPreview from '@/components/AgendaV2/AgendaPreview.vue';
import AgendaFilter from '@/components/AgendaV2/AgendaFilter.vue';
import AgendaPinned from '@/components/AgendaV2/AgendaPinned.vue';
import LinkButton from '@/common/components/LinkButton';
import SearchBar from '@/common/components/SearchBar';
import BaseModal from '@/common/components/BaseModal';
import BaseButton from '@/common/components/BaseButton';
import Restricted from '@/common/components/Restricted';

import { AGENDA_STATUS_MAP } from '@/common/constants';
import { formatDate } from '@/common/helpers/date';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const agendaRepository = RepositoryFactory.get('agenda');

export default {
  name: 'Agenda',
  components: {
    AgendaTable,
    AgendaPreview,
    AgendaFilter,
    AgendaPinned,
    LinkButton,
    SearchBar,
    BaseButton,
    BaseModal,
    Restricted,
  },
  provide() {
    return {
      isDisabledPinButton: computed(() => this.pinnedCount.pinned >= this.pinnedCount.maxPinned),
    };
  },
  data() {
    return {
      events: [],
      meta: {
        total_count: 0,
        total_page: 0,
        current_page: 1,
        per_page: 10,
      },
      params: {
        start_date: '',
        end_date: '',
        per_page: 10,
        page: 1,
        sort_by: '',
        sort_order: '',
        cat: [],
        type: '',
        q: '',
      },
      isPreviewModalOpen: false,
      isDeletePromptOpen: false,
      isPinModalOpen: false,
      isUnpinModalOpen: false,
      eventDetail: {},
      loading: false,
      deleteLoading: false,
      pinnedCount: {
        pinned: 0,
        maxPinned: 0,
      },
    };
  },
  computed: {
    items() {
      if (Array.isArray(this.events) && !!this.events.length) {
        return this.events.map((event) => ({
          id: event.id,
          title: event.title,
          category: event.category,
          date: formatDate(event.date, 'dd/MM/yyyy'),
          time: `${event.start_hour.substring(0, 5)} - ${event.end_hour.substring(0, 5)}`,
          status: this.getEventStatus(event.status),
          type: event.type,
          availability: event.availability,
          is_pinned: event.is_pinned,
        }));
      }

      return [];
    },
  },
  created() {
    const isAgendaV2Enabled = this.$store.state.featureFlag.agendaV2;

    if (!isAgendaV2Enabled) {
      this.$router.replace('/agenda');
    }
  },
  async mounted() {
    await this.fetchEvents();
    await this.getPinnedCount();
  },
  methods: {
    async fetchEvents() {
      try {
        this.loading = true;

        const response = await agendaRepository.getEvents(this.params);
        const { data, meta } = response.data;

        this.events = data;
        this.meta = meta;
      } catch (error) {
        this.$toast({
          type: 'error',
          message: 'Gagal mendapatkan data Agenda, silakan coba beberapa saat lagi',
        });
      } finally {
        this.loading = false;
      }
    },

    /**
     * Delete event by id
     * @param {number} id - id of event to delete
     */
    async deleteEvent(id) {
      try {
        this.deleteLoading = true;
        await agendaRepository.deleteEvent(id);
        this.$toast({
          type: 'success',
          message: 'Data agenda telah berhasil dihapus',
        });
      } catch (error) {
        this.$toast({
          type: 'error',
          message: 'Data agenda gagal dihapus',
        });
      } finally {
        this.deleteLoading = false;
        this.closeDeletePrompt();
        this.fetchEvents();
        this.getPinnedCount();
      }
    },

    async handlePinAgenda(agendaId, status) {
      try {
        const response = await agendaRepository.handlePinAgenda({ is_pinned: status }, agendaId);

        if (response.status === 200) {
          if (status) {
            this.$toast({
              type: 'success',
              message: 'Berhasil Sematkan Agenda',
            });
          } else {
            this.$toast({
              type: 'success',
              message: 'Berhasil Batalkan Sematkan Agenda',
            });
          }
        }
      } catch (error) {
        this.$toast({ type: 'error', message: 'Mohon maaf, gagal sematkan agenda' });
      } finally {
        this.deleteLoading = false;
        await this.fetchEvents();
        await this.getPinnedCount();
      }
    },

    /**
     * get agenda by id
     * @param {number} id - id of agenda
     */
    async getAgendaById(id) {
      try {
        const response = await agendaRepository.getEventById(id);
        const { data } = response.data;

        this.setEventDetail(data);
      } catch (error) {
        this.$toast({
          type: 'error',
          message: 'Gagal mendapatkan data Agenda, silakan coba beberapa saat lagi',
        });
      } finally {
        this.isPreviewModalOpen = true;
      }
    },

    /**
     * Set new query params based on argument
     *
     * @param {object} data - object containing new params
     */
    setParams(data) {
      const newParams = { ...this.params, ...data };
      this.params = { ...newParams };
    },

    /**
     * Set new params when search input changes
     * and fetch events again
     *
     * @param {string} query - search query
     */
    onSearch(query) {
      this.setParams({ q: query });
      this.fetchEvents();
    },

    /**
     * Set new params when pagination changes
     * and fetch events again
     *
     * @param {object} data - object cotaining new param based on emit values
     * @property {string} page
     * @property {string} per_page
     */
    onUpdatePagination(data) {
      this.setParams(data);
      this.fetchEvents();
    },

    /**
     * Set new params when filter changes
     * and fetch events again
     *
     * @param {object} data - object cotaining new param based on emit values
     * @property {string} start_date
     * @property {string} end_date
     * @property {Array} cat
     * @property {Array} type
     */
    onChangeFilter(data) {
      this.setParams(data);
      this.fetchEvents();
    },

    onChangeSort(data) {
      // destructure and give alias to prevent eslint error
      const { sort_by: sortBy, sort_order: sortOrder } = data;

      if (sortOrder === 'NO-SORT') {
        this.setParams({
          sort_by: null,
          sort_order: null,
        });
      } else if (sortBy === 'time') {
        this.setParams({
          sort_by: 'start_hour',
          sort_order: sortOrder,
        });
      } else {
        this.setParams(data);
      }

      this.fetchEvents();
    },

    getEventStatus(status) {
      return AGENDA_STATUS_MAP[status] ?? status;
    },

    /**
     * Filter events data by id
     * @param {number} id - id of specific event to filter
     * @returns {Object}
     */
    filterEventsById(id) {
      return this.events.filter((event) => event.id === id)[0];
    },

    /**
     * Set eventDetail data property
     * @param {Object} event - event detail
     */
    setEventDetail(event) {
      this.eventDetail = { ...event };
    },

    clearEventDetail() {
      this.eventDetail = {};
    },

    /**
     * Handle data filtering and data mutation
     * when preview button clicked
     * @param {number} id - id of specific event
     */
    handleShowPreview(id) {
      const event = this.filterEventsById(id);
      this.setEventDetail(event);
      this.togglePreviewModal();
    },

    /**
     * Handle delete event when delete button clicked
     * @param {number} id - id of specific event
     */
    handleDeleteAction(id) {
      const event = this.filterEventsById(id);
      this.setEventDetail(event);
      this.toggleDeletePrompt();
    },

    /**
     * Handle Pin agenda
     * @param {object} agenda - agenda detail
     */
    updatePinAgenda(agenda) {
      const status = !agenda.is_pinned;

      this.handlePinAgenda(agenda.id, status);
      this.closePinModal();
    },

    /**
     * Handle Unpin agenda
     * @param {object} agenda - agenda detail
     */
    updateUnpinAgenda(agenda) {
      const status = !agenda.is_pinned;

      this.handlePinAgenda(agenda.id, status);
      this.closeUnpinModal();
    },

    /**
     * Set pin agenda
     * @param {object} agenda - agenda detaiil
     */
    setPinAgenda(agenda) {
      this.setEventDetail(agenda);
      this.togglePinPrompt();
    },
    /**
     * Set unpin agenda
     * @param {object} agenda - agenda detaiil
     */
    setUnpinAgenda(agenda) {
      this.setEventDetail(agenda);
      this.toggleUnpinPrompt();
    },

    togglePreviewModal() {
      this.isPreviewModalOpen = !this.isPreviewModalOpen;
    },

    toggleDeletePrompt() {
      this.isDeletePromptOpen = !this.isDeletePromptOpen;
    },

    togglePinPrompt() {
      this.isPinModalOpen = !this.isPinModalOpen;
    },

    toggleUnpinPrompt() {
      this.isUnpinModalOpen = !this.isUnpinModalOpen;
    },

    closeDeletePrompt() {
      this.clearEventDetail();
      this.toggleDeletePrompt();
    },

    closePinModal() {
      this.clearEventDetail();
      this.isPinModalOpen = !this.isPinModalOpen;
    },

    closeUnpinModal() {
      this.clearEventDetail();
      this.isUnpinModalOpen = !this.isUnpinModalOpen;
    },

    closePreviewModal() {
      this.clearEventDetail();
      this.togglePreviewModal();
    },

    async getPinnedCount() {
      try {
        const response = await agendaRepository.getPinnedCount();
        const { data } = response.data;

        this.pinnedCount.pinned = data.pinned;
        this.pinnedCount.maxPinned = data.max_pinned;
      } catch (error) {
        this.$toast({
          type: 'error',
          message: 'Gagal mendapatkan data perhitungan sematkan, silakan coba beberapa saat lagi',
        });
      }
    },
  },
};
</script>
