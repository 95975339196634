<template>
  <div class="flex items-center space-x-2 border border-gray-300 rounded-full h-[35px] px-3 py-2">
    <span class="text-gray-700 font-medium">Sematan Agenda :</span>
    <span class="font-bold text-gray-800">{{ pinned }}/{{ maxPinned }}</span>
  </div>
</template>

<script>
export default {
  props: {
    pinned: {
      type: Number,
      default: 0,
    },
    maxPinned: {
      type: Number,
      default: 6,
    },
  },
};
</script>
