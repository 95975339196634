/**
 * Currently active feature flag.
 * Please evaluate this feature flag list after each release
 */
export default {
  namespaced: true,
  state: {
    agendaV2: false,
    // ... add new feature here
  },
  mutations: {
    SET_FEATURE_FLAG(state, { stateName, value }) {
      if (stateName in state) {
        state[stateName] = value;
      }
    },
  },
  actions: {
    setFeatureFlag({ commit }, { stateName = '', value = false }) {
      commit('SET_FEATURE_FLAG', { stateName, value });
    },
  },
};
